import React from "react";
import Link from "next/link";
import styles from "./HomePage.module.scss";
import dynamic from "next/dynamic";
import { PlayIcon } from "@heroicons/react/outline";
const ModalVideo = dynamic(() => import("react-modal-video"), {
  ssr: false,
});

const AboutArea = () => {
  // Popup Video
  const [isOpen, setIsOpen] = React.useState(true);
  const openModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <React.Fragment>
      <div className={`${styles["about-area-two"]} pb-100`}>
        <div className={styles["container"]}>
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12">
              <div className={styles["about-content-box"]}>
                <span className={`${styles["sub-title"]} text-center`}>
                  Distance Learning
                </span>
                <h2>Why choose ToppersNotes for your Exam preparation?</h2>
                <p>
                  ToppersNotes are clean and precise to the particular exam you
                  are preparing for. We have the trust of 1,60,000+ happy
                  students across 100+ exams. Since our notes are so focused and
                  properly indexed, they are very useful in saving time and
                  revising during peak hours. At the same time, they are very
                  cost-efficient and will save you lots of hard-earned money.
                  Flowcharts, Maps, Infographics, Detailed Diagrams and
                  Mnemonics are all included which help you remember the
                  syllabus for life. All of this is curated and prepared by the
                  Top faculties, Toppers and the experienced ToppersNotes team
                </p>
                {/* <p>
                  <strong>
                    Grow your knowledge and your opportunities with thought
                    leadership, training and tools.
                  </strong>
                </p> */}
                <div className="text-center">
                  <Link href="/contact">
                    <a className={styles["link-btn"]}>Explore Learning</a>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-7 col-md-12">
              <div className={styles["about-video-box"]}>
                <div
                  //  className={styles["image"]}
                  className="bg-gray-50"
                >
                  <img
                    className="w-full h-full object-cover"
                    src={"/images/home-thumbnail.webp"}
                    alt="image"
                  />
                </div>

                <Link href="#play-video">
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      openModal();
                    }}
                    className={`${styles["video-btn"]} popup-youtube`}
                  >
                    <PlayIcon />
                    {/* <i className="flaticon-play"></i> */}
                  </a>
                </Link>

                {/* <div className={styles["shape10"]}>
                  <img src="/images/shape9.png" alt="image" />
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className={styles["shape3"]}>
          <img src="/images/shape3.png" alt="image" />
        </div>
        <div className={styles["shape4"]}>
          <img src="/images/shape4.png" alt="image" />
        </div>
        <div className={styles["shape2"]}>
          <img src="/images/shape2.png" alt="image" />
        </div>
      </div>

      {/* If you want to change the video need to update videoID */}
      <ModalVideo
        channel="youtube"
        isOpen={!isOpen}
        videoId="hu5i5pk8yts"
        onClose={() => setIsOpen(!isOpen)}
      />
    </React.Fragment>
  );
};

export default AboutArea;
